<template>
  <div class="an">
    <a-row :gutter="20">
      <a-col :sm="24" :xl="24" :style="{ marginBottom: '20px' }">
        <a-card style="min-height: 150px" title="任务栏" size="small">
          <div class="row2">
            <div
              class="aDiv aDiv1"
              v-if="see_shouli"
              @click="goPage('/project_list')"
            >
              <a-badge :count="volunteer_reviewing">
                <a-icon type="file-unknown" class="icon2" />
              </a-badge>
              <div>待审核</div>
            </div>

            <div
              class="aDiv aDiv2"
              v-if="see_lingyang"
              @click="goPage('/clock_list')"
            >
              <a-badge :count="member_miss_checkin"
                ><a-icon type="alert" class="icon2"
              /></a-badge>
              <div>未打卡</div>
            </div>

            <div
              class="aDiv aDiv3"
              v-if="see_test"
              @click="goPage('/yuyue_list')"
            >
              <a-badge :count="member_reserve_miss"
                ><a-icon type="clock-circle" class="icon2"
              /></a-badge>
              <div>未预约</div>
            </div>

            <div
              class="aDiv aDiv4"
              v-if="see_bg"
              @click="goPage('/yuyue_list')"
            >
              <a-badge :count="member_reserve_miss_signed"
                ><a-icon type="copy" class="icon2"
              /></a-badge>
              <div>未签到</div>
            </div>

            <div
              class="aDiv aDiv5"
              v-if="see_bg"
              @click="goPage('/questionnaire_list')"
            >
              <a-badge :count="member_question_miss"
                ><a-icon type="file-search" class="icon2"
              /></a-badge>
              <div>未填写调查问卷</div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-card size="small" style="margin-bottom: 20px" v-if="see_js">
      <template #title>项目统计</template>
      <template #extra>
        <div class="extra-item">
          <a
            @click="selDateType('proQueryTime', 1)"
            :class="{ active: proQueryTime.dateType == 1 }"
            >今日</a
          >
          <a
            @click="selDateType('proQueryTime', 2)"
            :class="{ active: proQueryTime.dateType == 2 }"
            >本月</a
          >
          <a
            @click="selDateType('proQueryTime', 3)"
            :class="{ active: proQueryTime.dateType == 3 }"
            >本年</a
          >
        </div>
        <a-range-picker
          :locale="locale"
          :style="{ width: '256px' }"
          @change="proTime"
          v-model="proQueryTime.range"
        />
      </template>

      <a-row :gutter="20">
        <a-spin :spinning="proLoad">
          <!-- <a-col :sm="24" :xl="18" :style="{ marginBottom: '20px' }">
            <proTable :tableData="proData" :pro_columns="pro_columns" />
          </a-col>

          <a-col :sm="24" :xl="6">
            <pie :data="proBi" id="proPie" name="项目占比" />
          </a-col> -->

          <div style="width: calc(100% - 500px); float: left">
            <vxe-grid
              class="mytable"
              size="small"
              ref="table"
              border="default"
              auto-resize
              resizable
              export-config
              import-config
              row-id="id"
              align="left"
              show-overflow
              highlight-hover-row
              :header-cell-class-name="headerCellClassName"
              :cell-class-name="cellClassName"
              :seq-config="{
                startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
              }"
              :pager-config="tablePage"
              :sort-config="{ trigger: 'cell' }"
              :columns="newCol"
              :proxy-config="tableProxy"
            >
              <template v-slot:fill="{ row, column }"></template>
            </vxe-grid>
          </div>

          <div style="width: 500px; float: right">
            <a-select
              style="width: 150px; margin-left: 20px"
              @change="biChange"
              :options="opt1"
              v-model="selBiType"
            ></a-select>
            <pie :data="proBi" id="proPie" name="项目占比" />
          </div>
        </a-spin>
      </a-row>
    </a-card>

    <!-- <a-card size="small" style="margin-bottom: 20px" v-if="see_js">
      <template #title>结算统计</template>
      <template #extra>
        <div class="extra-item">
          <a
            @click="selDateType('sellQueryTime', 1)"
            :class="{ active: sellQueryTime.dateType == 1 }"
            >今日</a
          >
          <a
            @click="selDateType('sellQueryTime', 2)"
            :class="{ active: sellQueryTime.dateType == 2 }"
            >本月</a
          >
          <a
            @click="selDateType('sellQueryTime', 3)"
            :class="{ active: sellQueryTime.dateType == 3 }"
            >本年</a
          >
        </div>
        <a-range-picker :style="{ width: '256px' }" @change="sellTime" />
      </template>

      <a-row :gutter="20">
        <a-spin :spinning="sellLoad">
          <a-col :sm="24" :xl="16" :style="{ marginBottom: '20px' }">
            <bar :data="sellData" id="sell" />
          </a-col>

          <a-col :sm="24" :xl="8" :style="{ marginBottom: '20px' }">
            <a-select
              style="width: 150px"
              @change="biChange"
              :options="opt1"
              v-model="selBiType"
            ></a-select>
            <pie :data="sellBi" id="zyPie" :name="biName" />
          </a-col>
        </a-spin>
      </a-row>
    </a-card>

    <a-card size="small" style="margin-bottom: 20px" v-if="see_testTj">
      <template #title>检测统计</template>
      <template #extra>
        <div class="extra-item">
          <a
            @click="selDateType('testQueryTime', 1)"
            :class="{ active: testQueryTime.dateType == 1 }"
            >今日</a
          >
          <a
            @click="selDateType('testQueryTime', 2)"
            :class="{ active: testQueryTime.dateType == 2 }"
            >本月</a
          >
          <a
            @click="selDateType('testQueryTime', 3)"
            :class="{ active: testQueryTime.dateType == 3 }"
            >本年</a
          >
        </div>
        <a-range-picker :style="{ width: '256px' }" @change="testTime" />
      </template>

      <a-row :gutter="20">
        <a-spin :spinning="testLoad">
          <a-col :sm="24" :xl="16" :style="{ marginBottom: '20px' }">
            <barLine :data="testData" id="jyLine" />
          </a-col>

          <a-col :sm="24" :xl="8" :style="{ marginBottom: '20px' }">
            <pie :data="testBi" id="testPie" name="实验室占比" />
          </a-col>
        </a-spin>
      </a-row>
    </a-card> -->

    <a-row :gutter="20">
      <a-col
        :sm="24"
        :xl="12"
        :style="{ marginBottom: '20px' }"
        v-if="see_table"
      >
        <a-card style="min-height: 400px" title="查询统计" size="small">
          <query-list></query-list>
        </a-card>
      </a-col>

      <a-col :sm="24" :xl="12" :style="{ marginBottom: '20px' }">
        <a-card style="min-height: 400px" title="公共消息" size="small">
          <noticeList />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import Bar from "./modules/bar";
import Pie from "./modules/pie";
import queryList from "./modules/queryList";
import barLine from "./modules/line";
import proTable from "./modules/proTabel";
import noticeList from "./modules/noticeList";

export default {
  name: "Analysis",
  computed: {
    newCol() {
      let col = [...this.columns, ...this.pro_columns];
      return col;
    },
  },
  components: {
    Bar,
    Pie,
    queryList,
    barLine,
    proTable,
    noticeList,
  },
  data() {
    let that = this;
    return {
      locale,
      proQueryTime: {
        start: "",
        end: "",
        dateType: 2,
        range: [
          moment(moment().startOf("month").format("YYYY-MM-DD")),
          moment(moment().endOf("month").format("YYYY-MM-DD")),
        ],
      },
      proData: [],
      proBi: [],
      staBi: [],
      typeBi: [],
      testList: [],
      proLoad: false,
      pro_columns: [],
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: async ({ page, sort, filters, form }) => {
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }

            let param_t =
              "?pageNum=1" + "&pageSize=100" + "&customer_id=" + customer_id;

            await that
              .$getAction(that.$Interface.tag_list + param_t + "&tag_type=i")
              .then((res) => {
                if (res.code == 200) {
                  if (res.data.list && res.data.list.length) {
                    for (let i in res.data.list) {
                      let a = res.data.list[i];
                      that.testList[a.id] = a.name;
                    }
                  }
                }
              });

            let q_start = that.proQueryTime.start;
            let q_end = that.proQueryTime.end;

            let param =
              "?pageNo=1&pageSize=100&createTime_begin&is_deleted=0&status=2,4,5,6,7,8" +
              "&createBegin=" +
              q_start +
              "&createEnd=" +
              q_end +
              "&customer_id=" +
              customer_id;

            return that
              .$getAction(that.$Interface.project_list + param)
              .then(async (res) => {
                if (res.code == 200) {
                  let data = res.data.list;
                  console.log(data);

                  let dateArr = [];
                  that.pro_columns = [];
                  let proData = [];
                  if (
                    that.proQueryTime.dateType == 0 ||
                    that.proQueryTime.dateType == 2
                  ) {
                    dateArr = that.getDateArr(
                      that.proQueryTime.start,
                      that.proQueryTime.end
                    );

                    let m_arr = that.getDateMonth(q_start, q_end);
                    for (let t in m_arr) {
                      let m = m_arr[t];
                      let m_col = [];
                      for (let c in dateArr) {
                        let d = dateArr[c];
                        if (m == moment(d).format("M")) {
                          let t = {
                            title: moment(d).format("DD"),
                            field: d,
                            align: "center",
                            width: 35,
                            slots: {
                              default: "fill",
                            },
                          };
                          m_col.push(t);
                        }
                      }
                      let o = {
                        title: m + "月",
                        align: "center",
                        children: m_col,
                      };
                      that.pro_columns.push(o);
                    }
                  }

                  // console.log(dateArr);

                  let staBi = [];
                  let typeBi = [];
                  let testBi = [];
                  let today = moment().format("YYYY-MM-DD");
                  for (let i in data) {
                    let a = data[i];
                    let statusT = a.status_text;
                    let typeT = a.project_type;
                    let inspect_tag_id = a.inspect_tag_id;
                    for (let t in inspect_tag_id) {
                      let k = inspect_tag_id[t];
                      if (that.testList[k]) {
                        let testT = that.testList[k];
                        if (!testBi[testT]) {
                          testBi[testT] = {
                            name: testT,
                            value: 0,
                          };
                        }
                        testBi[testT].value++;
                      }
                    }

                    if (!staBi[statusT]) {
                      staBi[statusT] = {
                        name: statusT,
                        value: 0,
                      };
                    }
                    staBi[statusT].value++;

                    if (!typeBi[typeT]) {
                      typeBi[typeT] = {
                        name: typeT,
                        value: 0,
                      };
                    }
                    typeBi[typeT].value++;

                    let hasPast = 0;
                    let col_time = a.extInfo.clock_time;
                    let all = col_time.length ? col_time.length : 0;
                    if (
                      that.proQueryTime.dateType == 0 ||
                      that.proQueryTime.dateType == 2
                    ) {
                      for (let j in col_time) {
                        let day = col_time[j].base_day;
                        if (
                          new Date(today).getTime() >= new Date(day).getTime()
                        ) {
                          a[day] = "past";
                          hasPast++;
                        } else {
                          a[day] = "future";
                        }
                      }
                    }

                    a.distance = moment(a.extInfo.clock_endTime).diff(
                      today,
                      "day"
                    );
                    if (a.distance < 0) {
                      a.distance = "";
                    }
                    a.complete = (hasPast / all).toFixed(2) * 100 + "%";
                    proData.push(a);
                  }
                  that.proData = proData;
                  that.testBi = Object.values(testBi);
                  that.staBi = Object.values(staBi);
                  that.typeBi = Object.values(typeBi);
                  that.proBi = Object.values(testBi);
                }

                return res;
              });
          },
        },
      },
      // 表头
      columns: [
        {
          type: "seq",
          minWidth: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
          fixed: "left",
        },
        {
          title: "项目名称",
          field: "project_name",
          minWidth: 100,
          fixed: "left",
        },
        {
          title: "开始时间",
          field: "extInfo.clock_startTime",
          minWidth: 90,
        },
        {
          title: "结束时间",
          field: "extInfo.clock_endTime",
          minWidth: 90,
        },
        {
          title: "距离结束时间",
          minWidth: 100,
          field: "distance",
        },
        {
          title: "状态",
          field: "status_text",
          minWidth: 70,
          slots: {
            default: ({ row }) => {
              let status = row.status_text;
              if (row.deleted_at) {
                status = "已删除";
              }
              return status;
            },
          },
        },
        {
          title: "完成率",
          minWidth: 60,
          field: "complete",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },

      see_yujin: false,
      see_shouli: false,
      see_lingyang: false,
      see_test: false,
      see_bg: false,
      see_js: false,
      see_testTj: false,
      see_table: false,

      indexStyle: 1,
      warnDay0: 0,
      warnDay1: 0,
      warnDay2: 0,
      warnDay3: 0,
      waitAccept: 0,
      waitTake: 0,
      waitTest: 0,
      waitMakeRep: 0,
      waitCheckRep: 0,
      waitIssueRep: 0,
      waitPrintRep: 0,
      waitSendRep: 0,

      member_miss_checkin: 0,
      member_question_miss: 0,
      member_reserve_miss: 0,
      member_reserve_miss_signed: 0,
      volunteer_reviewing: 0,

      dateType: 2,

      sellData: { x_data: [], y_data: [] },
      opt1: [
        { label: "项目占比", value: 1 },
        { label: "类型占比", value: 2 },
        { label: "状态占比", value: 3 },
      ],
      biName: "项目占比",
      selBiType: 1,
      keHuBi: [],
      yeWuBi: [],
      sellBi: [],
      testLine: [],
      testData: { x_data: [], y_data: [] },
      testBi: [],
      sellQueryTime: {
        start: "",
        end: "",
        dateType: 2,
      },
      sellLoad: false,
      testQueryTime: {
        start: "",
        end: "",
        dateType: 2,
      },
      testLoad: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let that = this;

      // let roles = that.$store.getters.userInfo.selectedroles;
      // console.log(roles);
      // if (roles.indexOf("管理员") > -1 || roles.indexOf("所长") > -1) {
      that.see_yujin = true;
      that.see_shouli = true;
      that.see_lingyang = true;
      that.see_test = true;
      that.see_bg = true;
      that.see_js = true;
      that.see_testTj = true;
      that.see_table = true;
      // }

      let start = moment().startOf("month").format("YYYY-MM-DD");
      let end = moment().endOf("month").format("YYYY-MM-DD");
      this.proQueryTime.start = start;
      this.proQueryTime.end = end;

      that.$getAction(that.$Interface.project_analyze).then((res) => {
        if (res.code == 200) {
          // console.log(res);
          let d = res.data;
          that.member_miss_checkin = d.member_miss_checkin; //未打卡
          that.member_question_miss = d.member_question_miss; //未填写调查问卷
          that.member_reserve_miss = d.member_reserve_miss; //未预约
          that.member_reserve_miss_signed = d.member_reserve_miss_signed; //未签到
          that.volunteer_reviewing = d.volunteer_reviewing; //待审核
        }
      });
    },
    goPage(path) {
      this.$router.push(path);
    },

    getProList() {
      let that = this;
      that.$refs.table.commitProxy("query");
    },

    getDateMonth(start, end) {
      let dateStart = moment(start);
      let dateEnd = moment(end);
      let timeValues = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        timeValues.push(dateStart.format("M"));
        dateStart.add(1, "month");
      }
      return timeValues;
    },

    getDateArr(startDate, endDate) {
      let daysList = [];
      let SDate = moment(startDate);
      let EDate = moment(endDate);
      daysList.push(SDate.format("YYYY-MM-DD"));
      while (SDate.add(1, "days").isBefore(EDate)) {
        daysList.push(SDate.format("YYYY-MM-DD"));
      }
      if (daysList.indexOf(EDate.format("YYYY-MM-DD")) == -1) {
        daysList.push(EDate.format("YYYY-MM-DD"));
      }
      return daysList;
    },

    cellClassName({ row, column }) {
      if (row[column.property] == "past") {
        return "bg1";
      }
      if (row[column.property] == "future") {
        return "bg0";
      }
    },
    headerCellClassName() {
      return "bgHead";
    },

    selDateType(prop, type) {
      let that = this;
      that[prop].dateType = type;
      if (type == 1) {
        that[prop].start = moment().format("YYYY-MM-DD");
        that[prop].end = moment().format("YYYY-MM-DD");
      }
      if (type == 2) {
        that[prop].start = moment().startOf("month").format("YYYY-MM-DD");
        that[prop].end = moment().endOf("month").format("YYYY-MM-DD");
      }
      if (type == 3) {
        that[prop].start = moment().startOf("year").format("YYYY-MM-DD");
        that[prop].end = moment().endOf("year").format("YYYY-MM-DD");
      }
      that[prop].range = [moment(that[prop].start), moment(that[prop].end)];
      if (prop == "proQueryTime") {
        that.getProList();
      }
      // if (prop == "testQueryTime") {
      //   that.getTestAllData();
      // }
    },

    proTime(data, arr) {
      this.proQueryTime.start = arr[0];
      this.proQueryTime.end = arr[1];
      if (arr[0] && arr[1]) {
        this.proQueryTime.dateType = 0;
        this.getProList();
      }
    },

    sellTime(data, arr) {
      this.sellQueryTime.start = arr[0];
      this.sellQueryTime.end = arr[1];
      this.sellQueryTime.dateType = 0;
      this.getSellData();
    },

    testTime(data, arr) {
      this.testQueryTime.start = arr[0];
      this.testQueryTime.end = arr[1];
      this.testQueryTime.dateType = 0;
      this.getTestAllData();
    },

    biChange() {
      if (this.selBiType == 1) {
        this.biName = "项目占比";
        this.proBi = this.testBi;
      }
      if (this.selBiType == 2) {
        this.biName = "类型占比";
        this.proBi = this.typeBi;
      }
      if (this.selBiType == 3) {
        this.biName = "状态占比";
        this.proBi = this.staBi;
      }
    },
  },
};
</script>

<style scoped>
.line {
  background: #e8e8e8;
  height: 1px;
  width: 100%;
  margin: 5px 0;
}

.num1 {
  font-size: 20px;
  color: black;
}

.row1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title1 {
  color: black;
  font-size: 16px;
}

.t-blue {
  color: #3aa1ff;
}

.t-red {
  color: red;
}

.t-green {
  color: green;
}

.t-org {
  color: #faad14;
}

.icon1 {
  cursor: pointer;
  color: #3aa1ff;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
}

.icon1:hover,
.aDiv:hover {
  transform: scale(1.1);
  cursor: pointer;
  /* background: #f5f7fa; */
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.icon2 {
  cursor: pointer;
  /* color: #3aa1ff; */
  color: white;
  font-size: 40px;
}

.aDiv {
  /* width: 100px; */
  width: 18%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  /* margin-right: 20px; */
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  transition: 0.2s;
}

.aDiv1 {
  background: #80ffa5;
}

.aDiv2 {
  background: #37a2ff;
}

.aDiv3 {
  background: #ffbf00;
}

.aDiv4 {
  background: #6438c6;
}

.aDiv5 {
  background: #00ddff;
}

.extra-item {
  display: inline-block;
}

.extra-item a {
  padding: 5px;
  margin-right: 10px;
}

.active {
  background: #3aa1ff;
  color: white;
  border-radius: 5px;
}
</style>

<style>
.mytable .bgHead {
  background: #305496;
  color: white;
}
.mytable .bg1 {
  background: #37a2ff;
}
.mytable .bg0 {
  background: #e0e0e0;
}

.mytable .vxe-header--column {
  background-image: none !important;
}
</style>