<!-- 项目列表 -->
<template>
  <div>
    <vxe-grid class="mytable" size="small" ref="table" border="default" auto-resize resizable export-config import-config
      row-id="id" align="left" show-overflow highlight-hover-row :header-cell-class-name="headerCellClassName"
      :cell-class-name="cellClassName" :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }" :pager-config="tablePage" :sort-config="{ trigger: 'cell' }" :columns="newCol" :proxy-config="tableProxy"
      :data2="tableData">
      <template v-slot:fill="{ row, column }"></template>
    </vxe-grid>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: ["tableData", "proQueryTime"],
  data() {
    let that = this;
    return {
      pro_columns: [],
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {

            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let q_start = that.proQueryTime.start;
            let q_end = that.proQueryTime.end;

            let param =
              "?pageNo=1&pageSize=100&createTime_begin&is_deleted=0" +
              "&createBegin=" +
              q_start +
              "&createEnd=" +
              q_end +
              "&customer_id=" +
              customer_id;

            return that
              .$getAction(that.$Interface.project_list + param)
              .then(async (res) => {
                if (res.code == 200) {
                  let data = res.data.list;
                  console.log(data);

                  let dateArr = [];
                  that.pro_columns = [];
                  let proData = [];
                  if (
                    that.proQueryTime.dateType == 0 ||
                    that.proQueryTime.dateType == 2
                  ) {
                    dateArr = that.getDateArr(
                      that.proQueryTime.start,
                      that.proQueryTime.end
                    );

                    let m_arr = that.getDateMonth(q_start, q_end);
                    for (let t in m_arr) {
                      let m = m_arr[t];
                      let m_col = [];
                      for (let c in dateArr) {
                        let d = dateArr[c];
                        if (m == moment(d).format("M")) {
                          let t = {
                            title: moment(d).format("DD"),
                            field: d,
                            align: "center",
                            width: 35,
                            slots: {
                              default: "fill",
                            },
                          };
                          m_col.push(t);
                        }
                      }
                      let o = {
                        title: m + "月",
                        align: "center",
                        children: m_col,
                      };
                      that.pro_columns.push(o);
                    }
                  }

                  // console.log(dateArr);

                  let biArr = [];
                  let today = moment().format("YYYY-MM-DD");
                  for (let i in data) {
                    let a = data[i];
                    let statusT = a.status_text;
                    if (!biArr[statusT]) {
                      biArr[statusT] = {
                        name: statusT,
                        value: 0,
                      };
                    }
                    biArr[statusT].value++;

                    let hasPast = 0;
                    let col_time = a.extInfo.clock_time;
                    let all = col_time.length ? col_time.length : 0;
                    if (
                      that.proQueryTime.dateType == 0 ||
                      that.proQueryTime.dateType == 2
                    ) {
                      for (let j in col_time) {
                        let day = col_time[j].base_day;
                        if (
                          new Date(today).getTime() >= new Date(day).getTime()
                        ) {
                          a[day] = "past";
                          hasPast++;
                        } else {
                          a[day] = "future";
                        }
                      }
                    }

                    a.distance = moment(a.extInfo.clock_endTime).diff(
                      today,
                      "day"
                    );
                    if (a.distance < 0) {
                      a.distance = "";
                    }
                    a.complete = (hasPast / all).toFixed(2) * 100 + "%";
                    proData.push(a);
                  }
                  console.log(proData);
                }

                return res
              });
          },
        },
      },
      // 表头
      columns: [
        {
          type: "seq",
          minWidth: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
          fixed: "left",
        },
        {
          title: "项目名称",
          field: "project_name",
          minWidth: 100,
          fixed: "left",
        },
        {
          title: "开始时间",
          field: "extInfo.clock_startTime",
          minWidth: 90,
        },
        {
          title: "结束时间",
          field: "extInfo.clock_endTime",
          minWidth: 90,
        },
        {
          title: "距离结束时间",
          minWidth: 100,
          field: "distance",
        },
        {
          title: "状态",
          field: "status_text",
          minWidth: 70,
          slots: {
            default: ({ row }) => {
              let status = row.status_text;
              if (row.deleted_at) {
                status = "已删除";
              }
              return status;
            },
          },
        },
        {
          title: "完成率",
          minWidth: 60,
          field: "complete",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
        // total: 2,
      },
    };
  },

  components: {},

  computed: {
    newCol() {
      let col = [...this.columns, ...this.pro_columns];
      return col;
    },
  },

  created() { },

  mounted() { },

  methods: {
    cellClassName({ row, column }) {
      if (row[column.property] == "past") {
        return "bg1";
      }
      if (row[column.property] == "future") {
        return "bg0";
      }
    },
    headerCellClassName() {
      return "bgHead";
    },
    getDateMonth(start, end) {
      let dateStart = moment(start);
      let dateEnd = moment(end);
      let timeValues = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        timeValues.push(dateStart.format("M"));
        dateStart.add(1, "month");
      }
      return timeValues;
    },

    getDateArr(startDate, endDate) {
      let daysList = [];
      let SDate = moment(startDate);
      let EDate = moment(endDate);
      daysList.push(SDate.format("YYYY-MM-DD"));
      while (SDate.add(1, "days").isBefore(EDate)) {
        daysList.push(SDate.format("YYYY-MM-DD"));
      }
      if (daysList.indexOf(EDate.format("YYYY-MM-DD")) == -1) {
        daysList.push(EDate.format("YYYY-MM-DD"));
      }
      return daysList;
    },
  },
};
</script>

<style scoped></style>

<style>
.mytable .bgHead {
  background: #305496;
  color: white;
}

.mytable .bg1 {
  background: #86ffad;
}

.mytable .bg0 {
  background: #e0e0e0;
}

.mytable .vxe-header--column {
  background-image: none !important;
}
</style>
