<!--项目分组 -->
<template>
  <a-card>
    <!-- <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="部门名称">
            <a-input placeholder="请输入" v-model="queryParam.code"></a-input>
          </a-form-item>
        </a-col>
       
        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div> -->

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>
    </vxe-grid>

    <personModal ref="personModal" @ok="handleOk" />

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="120"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="项目组名称"
          field="team_name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item title="成员" field="sel_person" span="24">
          <template v-slot>
            <div>
              <a-input
                style="width: calc(100% - 80px)"
                v-model="sel_person"
              ></a-input>
              <a-button type="primary" style="margin-left: 10px" @click="open"
                >选择</a-button
              >
            </div>
          </template>
        </vxe-form-item>

        <vxe-form-item
          title="状态"
          field="team_status"
          span="24"
          :item-render="{
            name: '$select',
            options: status_opt,
            attrs: { placeholder: '请输入' },
          }"
        ></vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </a-card>
</template>

<script>
import personModal from "./modal/personModal.vue";
export default {
  data() {
    let that = this;
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      sel_person: "",
      member: [],
      url: {
        list: "",
      },
      status_opt: [
        { label: "有效", value: "Y" },
        { label: "无效", value: "N" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          width: "100",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "项目组名称",
          field: "team_name",
        },
        {
          title: "成员",
          slots: {
            default: ({ row }) => {
              let str;
              if (row.member.length) {
                str = row.member
                  .map((it) => {
                    return it.staff_nick;
                  })
                  .join("，");
              }
              return str;
            },
          },
        },
        {
          title: "状态",
          field: "team_status_text",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.List",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // console.log(this.queryParam);
            // return _this.$Request(this.$Interface.warn_info);
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&customer_id=" +
              customer_id;
            return that.$getAction(that.$Interface.group_list + param);

            let data = {
              result: {
                records: [
                  {
                    name: "测试组1",
                    person: "admin",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        team_status: "Y",
      },
      formRules: {
        team_name: [{ required: true, message: "请输入" }],
        sel_person: [{ required: true, message: "请选择" }],
        team_status: [{ required: true, message: "请选择" }],
      },
    };
  },

  components: { personModal },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    open() {
      this.$refs.personModal.show();
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
      this.formData = {
        team_status: "Y",
      };
      this.sel_person = ''
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this
      that.$postAction(that.$Interface.group_del,{ids:ids}).then(res=>{
        console.log('res',res);
      })
    },
    submitEvent() {
      let that = this;
      let customer_id = "";
      customer_id = JSON.parse(sessionStorage.userInfo).user_id;
      if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
        customer_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      }
      console.log(that.member);
      let param = {
        ...that.formData,
        member: that.member,
      };
      // param.customer_id = customer_id;
      if (!param.customer_id) {
        param.customer_id = customer_id;
      }
      that.$postAction(that.$Interface.group_edit, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("保存成功");
          that.loadData();
          that.subModal = false;
        } else {
          that.$message.error("保存失败");
        }
      });
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      if (row.member.length) {
        this.sel_person = row.member
          .map((it) => {
            return it.staff_nick;
          })
          .join("，");
      }
    },

    handleOk(data) {
      let that = this;
      that.member = [];
      let sel_name = data.map((it) => {
        let a = { staff_id: it.id };
        that.member.push(a);
        return it.nick_name;
      });
      that.sel_person = sel_name.join(",");
      that.formData.sel_person = that.sel_person;
    },
  },
};
</script>

<style scoped>
</style>
