<!-- 样品列表 -->
<template>
  <vxe-modal  style="z-index: 10"  
    v-model="showModal"
    title="样品列表"
    width="800"
    height="600"
    resize
    destroy-on-close
    show-zoom
  >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      @radio-change="radioChangeEvent"
    >
    </vxe-grid>
    <a-button @click="insertEvent()" type="primary" style="float:right;margin-left: 10px;">新增</a-button>
    <a-button @click="handleOk" type="primary" style="float:right">确定</a-button>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      title="新增"
      width="1000"
      height="90%"
      resize
      destroy-on-close
      show-zoom
    >
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="formRules"
        class="ant-advanced-search-form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col span="24">
            <div class="title" style="width: 100%">样品信息</div>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品编号" prop="product_no">
              <a-input
                placeholder="请输入"
                v-model="formData.product_no"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品名称" prop="name">
              <a-input placeholder="请输入" v-model="formData.name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品英文名称">
              <a-input
                placeholder="请输入"
                v-model="formData.name_en"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品类别">
              <a-input
                placeholder="请输入"
                v-model="formData.type_name"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="规格型号">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleModal"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品性状">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleCharacter"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="颜色物态">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleColorState"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="数量单位">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleUnit"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="生产日期或批号">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleBatchNumber"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="保质期或限用日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.sampleLimitDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="保存条件">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleSaveCondition"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品备注">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleRemark"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">送检信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionPerson"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.inspectionDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="受理人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.acceptPerson"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="受理日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.acceptDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="送检单位地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.inspectionAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">生产单位信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产国">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productContry"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="生产企业地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.productAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">境内单位信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="境内单位地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.domesticAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="submitEvent"> 保存 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清空
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </vxe-modal>
  </vxe-modal>

  
</template>

<script>
export default {
  data() {
    let that = this;
    return {
      showModal: false,
      subModal: false,
      selectRow: {},
      template: {},
      queryParam: {},
      formData: {
        extInfo: {},
      },
      columns: [
        { type: "radio", width: 45 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "样品编号",
          field: "product_no",
        },
        {
          title: "样品名称",
          field: "name",
        },
        {
          title: "样品类别",
          field: "type_name",
        },
        {
          title: "样品备注",
          field: "extInfo.sampleRemark",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
           let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize;
            return that.$getAction(that.$Interface.sample_list + param);

          },
        },
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show() {
      this.showModal = true;
    },
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    handleOk() {
      this.$emit("ok", this.selectRow);
      this.showModal = false;
    },
    insertEvent() {
      this.subModal = true;
      // this.selectRow = false;
      this.formData = {
        extInfo: {},
      };
    },
    submitEvent() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.formData));
          if (!param.customer_id) {
            let customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            let c_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
            if (c_id) {
              customer_id = c_id;
            }
            param.customer_id = customer_id;
          }

          that.$postAction(that.$Interface.sample_edit, param).then((res) => {
            if (res.code == 200) {
              that.$message.success("保存成功");
              that.loadData();
              that.subModal = false;
            } else {
              that.$message.error("保存失败");
            }
          });
        } else {
          return false;
        }
      });
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
  },
};
</script>

<style scoped>
</style>
