<!-- 日 时间 选择 -->
<template>
  <div style="display: flex; flex-wrap: wrap">
    <calendar
      :month="c_month"
      :selectFestival.sync="festival"
      :restDate.sync="restDate"
      :festivalObj.sync="festivalObj"
      @dateChange="dateChange"
      v-if="size == 'samll'"
    />
    <calendar_yuyue
      :month="c_month"
      :selectFestival.sync="festival"
      :restDate.sync="restDate"
      :festivalObj.sync="festivalObj"
      @dateChange="dateChange"
      v-if="size == 'big'"
    />
    
    <vxe-grid
      style="margin-top: 5px; margin-left: 10px; width: 800px"
      size="small"
      resizable
      row-id="id"
      show-header-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      border
      :data="yuyue_arr"
      :columns="columnsYu"
    >
      <template v-slot:action="{ row }">
        <!-- <a @click="removeDay(row)">删除</a> -->
        <a-popconfirm
          title="确认删除?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="removeDay(row)"
        >
          <a href="#">删除</a>
        </a-popconfirm>

        <a style="margin-left: 8px" @click="copyDay(row)">复制</a>
      </template>
      <template v-slot:day="{ row }">
        <a-date-picker
          placeholder="请选择"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
          v-model="row.base_day"
        ></a-date-picker>
      </template>
      <template v-slot:items="{ row }">
        <div
          v-for="(item, index) in row.items"
          :key="index"
          style="margin-top: 5px"
        >
          <a-icon type="scissor" class="scissor" @click="split(item, row)" />

          <a-time-picker
            v-model="item.begin_time"
            format="HH:mm"
            valueFormat="HH:mm"
            placeholder="请选择"
            :minute-step="10"
            style="width: 100px; margin-left: 5px"
          />
          ~
          <a-time-picker
            v-model="item.end_time"
            format="HH:mm"
            valueFormat="HH:mm"
            placeholder="请选择"
            style="width: 100px"
            :minute-step="10"
          />

          <template v-if="personSet == 1">
            人数上限：
            <a-input-number
              style="width: 60px"
              :min="1"
              v-model="item.max_num"
            />
          </template>

          <a-icon
            type="close-circle"
            class="closeBadeg"
            @click="delTime(index, row.items)"
          />
        </div>

        <a-button
          @click="addTime(row)"
          size="small"
          primary
          type="primary"
          block
          style="margin-top: 10px"
          >添加</a-button
        >
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="timeModal"
      title="设置时间间隔"
      show-zoom
      resize
      destroy-on-close
      width="300"
      height="auto"
    >
      <div style="margin-bottom: 10px">
        {{ timeSplit.begin_time }} ~ {{ timeSplit.end_time }}，人数上限：{{
          timeSplit.max_num
        }}
      </div>

      <div>
        间隔：<a-select
          :options="optTime"
          v-model="splitV"
          style="width: 100px"
        ></a-select>
      </div>
      <div style="text-align: center; margin: 15px">
        <a-button type="primary" @click="setSplit" style="margin-right: 15px"
          >确定</a-button
        >
        <a-button @click="cancelSplit">取消</a-button>
      </div>
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import calendar from "@/components/calendar/calendar";
import calendar_yuyue from "@/components/calendar/calendar_yuyue";

export default {
  props: {
    personSet: {
      type: String,
      default: "1",
    },
    size: {
      type: String,
      default: "samll",
    },
  },
  data() {
    let that = this;
    return {
      locale,
      timeModal: false,
      dayRow: {},
      timeSplit: {},
      itemIndex: "",
      optTime: [],
      splitV: 10,
      festival: [],
      restDate: [],
      festivalObj: {},
      dateArr: "",

      selectionRows: "",
      selectedRowKeys: "",
      c_day: "",
      c_month: new Date().getMonth() + 1,
      yuyue_arr: [],
      columnsYu: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
          width: 80,
        },
        {
          title: "日期",
          slots: {
            default: "day",
          },
          //   field: "day",
          width: 140,
        },
        {
          title: "时间段",
          slots: {
            default: "items",
          },
        },
      ],
    };
  },

  components: { calendar, calendar_yuyue },

  computed: {},

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },

    init(arr) {
      let that = this;
      if (arr) {
        this.yuyue_arr = [];
        this.yuyue_arr = arr;
        this.restDate = [];
        for (let i in this.yuyue_arr) {
          let day = this.yuyue_arr[i].base_day;
          this.restDate.push(day);
        }
      }
      that.optTime = [];
      for (let i = 10; i <= 60; i += 10) {
        let a = {
          label: i + "分钟",
          value: i,
        };
        that.optTime.push(a);
      }
    },

    dateChange(arr) {
      let that = this;
      let newArr = [];
      for (let i in arr) {
        let a = arr[i];
        let dayHas = _.findIndex(that.yuyue_arr, ["base_day", a]);
        if (dayHas == -1) {
          let t = {
            base_day: a,
            items: [{ end_time: "", begin_time: "", max_num: "" }],
          };
          newArr.push(t);
        } else {
          let t = that.yuyue_arr[dayHas];
          newArr.push(t);
        }
      }
      that.yuyue_arr = newArr;
    },

    addTime(row) {
      let t = {
        base_day: row.base_day,
        items: [{ end_time: "", begin_time: "", max_num: "" }],
      };
      row.items.push(t);
    },

    delTime(index, arr) {
      if (arr.length == 1) {
        this.$message.warning("至少选择一个时间段");
        return;
      }
      arr.splice(index, 1);
    },

    removeDay(row) {
      let that = this;
      let index = _.indexOf(that.yuyue_arr, row);
      that.yuyue_arr.splice(index, 1);
      let dayIndex = _.indexOf(that.restDate, row.base_day);
      that.restDate.splice(dayIndex, 1);
      let delRow = {
        base_day: row.base_day,
        items: [],
      };
      that.$emit("del", delRow);
    },
    copyDay(row) {
      let that = this;
      // that.yuyue_arr.push(row);
      // that.restDate.push(row.day);
      for (let i in that.yuyue_arr) {
        let a = that.yuyue_arr[i];
        let item = JSON.parse(JSON.stringify(row.items));
        for (let i in item) {
          delete item[i].id;
        }
        if (a.base_day != row.base_day) {
          a.items = item;
        }
      }
    },

    split(item, row) {
      let that = this;
      if (!item.begin_time || !item.end_time) {
        this.$message.error("时间范围不能为空，请选择");
        return;
      }

      this.timeModal = true;
      this.timeSplit = item;
      this.dayRow = row;
      this.itemIndex = _.findIndex(this.dayRow.items, item);
    },
    setSplit() {
      let that = this;
      this.dayRow.items.splice(this.itemIndex, 1);
      let start = this.timeSplit.begin_time;
      let end = this.timeSplit.end_time;
      let s_arr = start.split(":");
      let s_hour = s_arr[0];
      let s_min = s_arr[1];
      let e_arr = end.split(":");
      let e_hour = e_arr[0];
      let e_min = e_arr[1];
      let split = this.splitV;
      let h_cha = parseInt(e_hour) - parseInt(s_hour);
      let m_cha = parseInt(e_min) - parseInt(s_min);
      let min_cha = h_cha * 60 + m_cha;
      for (let i = 0; i < min_cha; i += split) {
        let hour = parseInt(s_hour) + Math.floor(i / 60);
        let min = i + parseInt(s_min) - Math.floor(i / 60) * 60;
        let s_time = "";

        if (i < min_cha) {
          s_time = changeT(hour) + ":" + changeT(min);
          // if (s_hour == e_hour && min >= parseInt(e_min)) {
          //   break;
          // }
          if (min == 60) {
            s_time = changeT(hour + 1) + ":" + "00";
          }
          if (min > 60) {
            let s_m = min + split - 60 - split;
            s_time = changeT(hour + 1) + ":" + changeT(s_m);
          }
        }

        if (
          changeT(hour) == changeT(e_hour) &&
          changeT(min) >= parseInt(e_min)
        ) {
          break;
        }
        if (
          changeT(hour) == changeT(e_hour) &&
          changeT(min + split) > parseInt(e_min)
        ) {
          break;
        }

        let e_time = changeT(hour) + ":" + changeT(min + split);
        if (min + split == 60) {
          e_time = changeT(hour + 1) + ":" + "00";
        }
        if (min + split > 60) {
          let e_m = min + split - 60;
          if (e_m > parseInt(e_min) && changeT(hour + 1) == e_hour) {
            break;
          }
          e_time = changeT(hour + 1) + ":" + changeT(e_m);
        }
        let a = {
          begin_time: s_time,
          end_time: e_time,
          max_num: that.timeSplit.max_num,
        };
        this.dayRow.items.push(a);
        console.log(s_time, e_time);
      }

      this.timeModal = false;

      function changeT(t) {
        let a = parseInt(t);
        if (a < 10) {
          a = "0" + a;
        }
        return a;
      }
    },
    cancelSplit() {
      this.timeModal = false;
      this.splitV = 10;
    },
  },
};
</script>

<style scoped>
.closeBadeg {
  color: red;
  cursor: pointer;
  z-index: 1;
  margin-left: 5px;
}

.scissor {
  color: #1890ff;
  cursor: pointer;
  z-index: 1;
}
</style>
